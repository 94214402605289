<template>
    <div class="mainContent" style="min-width: 312px;">
        <div :style="newsListStyles" style="padding-right: 10px;">
            <div v-for="newsItem in news" :key="newsItem.id">
                <span class="larger bolder skyblue">{{ newsItem.title }}</span><br />
                <span class="smaller">{{ newsItem.posted }}</span>
                <p>
                    {{ newsItem.story }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>

    const news = [
        //{
        //    title: "",
        //    posted: ,
        //    story: ""
        //},
        {
            id: 1,
            title: "A Quarter Century of WolfPk",
            posted: "2021-Oct-22",
            story: "It's hard to believe I was still in high school when we started WolfPk. I emailed John Beaston at EasyStreet and asked them to secure the wolfpk.com domain name. It was done that day, October 22nd, 1996. The internet was a much different beast back then. With the exception of a few phenomenal science-fiction writers, I doubt anyone could have predicted the combination hell-scape toxic wasteland and economic super-pipeline that would eventually come to define the internet."
        },
    ]

    export default {
        created() {
            window.document.title = 'Chris Davais - News'
        },
        computed: {
            newsListStyles() {
                return {
                    "max-height": `${window.innerHeight - 700}px`,
                    "overflow-y": 'auto'
                };
            }
        },
        data: () => ({
            news: news
        })
    }
</script>
