<template>
    <div id="divLegal">
        <div style="color: white; text-align: center">{{ photoTitle }}</div>
        Photo by <a :href="photoUrl">{{ photoCredit }}</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
        <div id="divCopy">
            The rest is &copy; myself
        </div>
    </div>
</template>

<script>
    export default {
        props: ['photoCredit', 'photoUrl', 'photoTitle']
    }
</script>

<style>
    #divLegal {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        color: lightslategray;
        font-size: x-small;
        padding: 10px 15px 15px 10px;
        position: fixed;
        bottom: -5px;
        right: -5px;
        white-space: nowrap;
    }

    #divCopy {
        border-top: 1px solid goldenrod;
        color: goldenrod;
        margin-top: 3px;
        padding-top: 3px;
        text-align: center;
    }
</style>