import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBook, faCommentDots, faEnvelope, faExclamationTriangle, faHome, faNewspaper, faTools, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

library.add(faUser)
library.add(faBook)
library.add(faCommentDots)
library.add(faEnvelope)
library.add(faExclamationTriangle)
library.add(faNewspaper)
library.add(faTools)
library.add(faHome)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(VueReCaptcha, { siteKey: '6LfFb7gUAAAAAJG8MXLcCeHKO45K2cUvZvHc3zyQ' })

import App from './components/Main.vue';

new Vue(App)
