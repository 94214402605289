<template>
    <div class="mainContent">
        <p>Greetings User,</p>
        <p>My name is Chris Davais and I would like to welcome you to my little corner of the web.</p>
        <p>This website is still very much under construction.</p>
        <p>If you want to know more about me, visit <a href="/about">this page</a>.</p>
        <p>If you need to contact me, please use <a href="/contact">this page</a>.</p>
        <p>I keep an updated list of <a href="/books">books</a> I'm either reading or recently finished.</p>
        <p>I also have a <a href="/news">news</a> page with more information about me.</p>
        <p class="signature">-Chris Davais</p>
    </div>
</template>

<script>
    export default {
        created() {
            window.document.title = 'Chris Davais - Home'
        }
    }
</script>

<style>
    .signature {
        color: goldenrod;
        font-weight: bolder;
    }
</style>