<template>
    <div id="wrapperMain" :style="{ 'background-image': 'url(' + routes[currentRoute].backgroundPhotoUrl + ')' }">
        <navigation :currentPage="this.routes[this.currentRoute].name" />
        <home v-if="this.routes[this.currentRoute].name === 'Home'" />
        <about v-else-if="this.routes[this.currentRoute].name === 'About'" />
        <contact v-else-if="this.routes[this.currentRoute].name === 'Contact'" />
        <books v-else-if="this.routes[this.currentRoute].name === 'Books'" />
        <news v-else-if="this.routes[this.currentRoute].name === 'News'" />
        <legal :photoTitle="this.routes[this.currentRoute].backgroundPhotoTitle"
               :photoCredit="this.routes[this.currentRoute].backgroundPhotoCredit"
               :photoUrl="this.routes[this.currentRoute].backgroundPhotoLink" />
    </div>
</template>

<script>
    import Home from './Home.vue'
    import About from './About.vue'
    import Contact from './Contact.vue'
    import Books from './Books.vue'
    import News from './News.vue'
    import Navigation from './Navigation.vue'
    import Legal from './Legal.vue'

    const routes = {
        '/': {
            component: Home,
            name: "Home",
            backgroundPhotoTitle: "Mt. Hood from Trillium Lake",
            backgroundPhotoUrl: "backgrounds/trillium.jpg",
            backgroundPhotoCredit: "Sean Martin",
            backgroundPhotoLink: "https://unsplash.com/@joeavocado?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        },
        '/about': {
            component: About,
            name: "About",
            backgroundPhotoTitle: "Wizard Island in Crater Lake",
            backgroundPhotoUrl: "backgrounds/craterlake.jpg",
            backgroundPhotoCredit: "Varadh Jain",
            backgroundPhotoLink: "https://unsplash.com/@anekanta?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        },
        '/contact': {
            component: Contact,
            name: "Contact",
            backgroundPhotoTitle: "Tillamook Rock Lighthouse",
            backgroundPhotoUrl: "backgrounds/lighthouse.jpg",
            backgroundPhotoCredit: "Julie Olsen",
            backgroundPhotoLink: "https://unsplash.com/@jule_horse?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        },
        '/books': {
            component: Books,
            name: "Books",
            backgroundPhotoTitle: "The Painted Hills",
            backgroundPhotoUrl: "backgrounds/paintedhills.jpg",
            backgroundPhotoCredit: "Dan Meyers",
            backgroundPhotoLink: "https://unsplash.com/@dmey503?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        },
        '/news': {
            component: News,
            name: "News",
            backgroundPhotoTitle: "Benson Footbridge at Multnomah Falls",
            backgroundPhotoUrl: "backgrounds/multnomahfalls.jpg",
            backgroundPhotoCredit: "Cristofer Maximilian",
            backgroundPhotoLink: "https://unsplash.com/@cristofer?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        }
    }

    export default {
        el: '#app',
        components: {
            Home,
            About,
            Contact,
            Books,
            News,
            Navigation,
            Legal
        },
        data: () => ({
            currentRoute: window.location.pathname,
            routes: routes
        })
    }
</script>

<style>
    body {
        color: white;
        margin: 0px;
        padding: 0px;
        overflow: clip;
    }

    * {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .float-right {
        float: right;
    }

    .larger {
        font-size: larger;
    }

    .smaller {
        font-size: smaller;
    }

    .bolder {
        font-weight: bolder;
    }

    .mono {
        font-family: Courier New, Courier, monospace;
    }

    .skyblue {
        color: skyblue;
    }

    a {
        color: skyblue;
        text-decoration: none;
    }

    a:hover {
        color: goldenrod;
    }

    #wrapperMain {
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        height: 100vh;
        width: stretch;
    }

    .mainContent {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        box-shadow: 3px 3px rgba(0, 0, 0, 0.175);
        clear: right;
        margin-top: 20px;
        padding: 10px;
        max-width: 33%;
        min-width: 170px;
        position: relative;
        left: 33%
    }
</style>
