<template>
    <div id="floaterNavigation">
        <img src="../assets/chris_davais.jpg" id="imageProfile" width="160" />
        <span id="spanTitle">Chris Davais</span>

        <div>
            <a href="/" v-if="this.currentPage !== 'Home'">
                <font-awesome-icon :icon="['fas', 'home']" />&nbsp; Home
            </a>
            <div v-if="this.currentPage === 'Home'" class="selected">
                <font-awesome-icon :icon="['fas', 'home']" />&nbsp; Home
            </div>
        </div>
        <div>
            <a href="/about" v-if="this.currentPage !== 'About'">
                <font-awesome-icon :icon="['fas', 'user']" />&nbsp; About
            </a>
            <div v-if="this.currentPage === 'About'" class="selected">
                <font-awesome-icon :icon="['fas', 'user']" />&nbsp; About
            </div>
        </div>
        <div>
            <a href="/contact" v-if="this.currentPage !== 'Contact'">
                <font-awesome-icon :icon="['fas', 'comment-dots']" />&nbsp; Contact
            </a>
            <div v-if="this.currentPage === 'Contact'" class="selected">
                <font-awesome-icon :icon="['fas', 'comment-dots']" />&nbsp; Contact
            </div>
        </div>
        <div>
            <a href="/books" v-if="this.currentPage !== 'Books'">
                <font-awesome-icon :icon="['fas', 'book']" />&nbsp; Books
            </a>
            <div v-if="this.currentPage === 'Books'" class="selected">
                <font-awesome-icon :icon="['fas', 'book']" />&nbsp; Books
            </div>
        </div>
        <div>
            <a href="/news" v-if="this.currentPage !== 'News'">
                <font-awesome-icon :icon="['fas', 'newspaper']" />&nbsp; News
            </a>
            <div v-if="this.currentPage === 'News'" class="selected">
                <font-awesome-icon :icon="['fas', 'newspaper']" />&nbsp; News
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['currentPage']
    }
</script>

<style>
    #floaterNavigation {
        float: right;
        position: relative;
        top: -5px;
        right: -5px;
        padding: 15px 15px 10px 10px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        box-shadow: 3px 3px rgba(0, 0, 0, 0.175);
        width: 185px;
        z-index: 2;
    }

    #imageProfile {
        border-radius: 5px;
    }

    #spanTitle {
        border-bottom: 1px solid goldenrod;
        color: goldenrod;
        display: block;
        font-weight: bold;
        margin-top: 5px;
        text-align: center;
        width: 100%;
    }

    #floaterNavigation div {
        margin-top: 10px;
        padding: 0px;
        position: relative;
        width: 195px;
        right: 0px;
    }

    #floaterNavigation div a {
        background-color: skyblue;
        border-radius: 3px;
        color: white;
        display: inline-block;
        font-weight: bolder;
        padding: 5px;
        width: 100%;
    }

    #floaterNavigation div a:hover {
        background-color: goldenrod;
        color: black;
    }

    #floaterNavigation div a svg {
        width: 20px;
    }

    #floaterNavigation div div svg {
        width: 20px;
    }

    #floaterNavigation div .selected {
        background-color: white;
        border-radius: 3px;
        color: black;
        display: inline-block;
        font-weight: bolder;
        margin-top: 0px;
        padding: 5px;
        width: 100%;
    }
</style>