<template>
    <div class="mainContent" style="min-width: 385px;">
        <div v-if="sent">
            Your message has been sent.
        </div>
        <div v-else>
            <label>Your Name:</label>
            <input v-model="from" />

            <label>Your Email or Phone:</label>
            <input v-model="contact" />

            <label>Message</label>
            <textarea v-model="message"></textarea>

            <br />
            <button class="btn btn-success" @click="recaptcha">Send Message &nbsp; <font-awesome-icon :icon="['fas', 'envelope']" /></button>
        </div>
    </div>
</template>

<script>
    export default {
        created() {
            window.document.title = 'Chris Davais - Contact'
        },
        data() {
            return {
                from: null,
                contact: null,
                message: null,
                sent: false
            }
        },
        methods: {
            async recaptcha() {
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('submit')

                var body = {
                    ConName: this.from,
                    ConValue: this.contact,
                    ConMessage: this.message,
                    'g-recaptcha-response': token
                }

                await fetch('/contact', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(body)
                });

                this.sent = true;
            }
        }
    }
</script>

<style>
    #divContactOutOfService {
        background-color: goldenrod;
        border-radius: 3px;
        font-weight: bolder;
        margin-bottom: 10px;
        padding: 5px;
        text-align: center;
    }

    .red {
        color: red;
    }

    label {
        display: block;
        font-weight: bold;
        color: goldenrod;
        padding-top: 10px;
    }

    .grecaptcha-badge {
        width: 70px !important;
        overflow: hidden !important;
        transition: all 0.3s ease !important;
        left: 4px !important;
    }

    .grecaptcha-badge:hover {
        width: 256px !important;
    }
</style>
