<template>
    <div class="mainContent">
        <p>
            First, thank you for taking the time to visit my site.
        </p>
        <p>
            Like you I'm a member of the human race.<sup>*</sup>
            The families of both of my parents originated in western and southern Europe.
            I was born near the west coast of the United States in the late 1970's.
        </p>
        <p>
            My formal education was conducted over 15 years at Montessori based schools.
            Experience based education started in 1979 and will never end.
        </p>
        <p>
            I have a few areas of interest that I study, including:
            <ul>
                <li>History</li>
                <li>Physics and Astrophysics</li>
                <li>Aeronautics and Astronautics</li>
                <li>Electronics, Computers and Networking</li>
                <li>Commercial and Amateur Radio</li>
            </ul>
        </p>
        <p>
            FAA licensed Single/Multi-engine Land Private Pilot with Instrument rating, certificate <span class="mono">#1996384</span>
        </p>
        <p>
            FCC licensed General Class amateur radio station operator, callsign: <span class="mono">KI7PTA</span>
        </p>
        <p class="smaller">
            <sup>*</sup> Apologies to any robots that might been reading this page.
        </p>
    </div>
</template>

<script>
    export default {
        created() {
            window.document.title = 'Chris Davais - About'
        }
    }
</script>
