<template>
    <div class="mainContent" style="min-width: 312px;">
        <p>
            If you knew anything <a href="/about">about me</a> you could probably guess the types of books I enjoy reading.
            History, Science, Fiction, you'll find them all in the list below.
        </p>

        <div id="divBookList" :style="bookListStyles" style="padding-right: 10px;">
            <div v-for="book in books" :key="book.id" class="pBook">
                <a :href="book.productUrl" target="_blank" class="bolder">{{ book.title }}</a>
                <img class="float-right" :src="book.coverImageUrl" width="100" style="margin-bottom: 10px" />
                <br />
                <i>{{ book.author }}</i>
                <p v-for="note in book.myNotes" :key="note.note">
                    {{ note.note }}
                </p>
            </div>
        </div>

        <div id="divLastUpdated" class="smaller">
            Last Updated 2022-Jan-30
        </div>
    </div>
</template>

<script>

    const books = [
        //{
        //    id: 0,
        //    title: "",
        //    author: "",
        //    coverImageUrl: "",
        //    productUrl: "",
        //    reading: false,
        //    myNotes: [

        //    ]
        //},
        {
            id: 10,
            title: "Easy Company Soldier",
            author: "Don Malarkey, Bob Welch",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/51pkxC1nSUL._SX332_BO1,204,203,200_.jpg",
            productUrl: "https://www.amazon.com/gp/product/031256323X",
            myNotes: [
                { note: "If you’ve seen the HBO mini-series Band of Brothers then the author of this book needs no introduction. He may start off as Private Bullshit, but by the end of World War II he had served continuously on the front-lines for longer than anyone else in his company." },
                { note: "From the Assault on Brecourt Manor, to Operation Market Garden in Holland, the infamous Battle of the Bulge in Bastogne and finally peace sometime later this man was there to participate in all of it. Special to me because he comes from the same part of the world that I do." }
            ]
        },
        {
            id: 9,
            title: "Brothers in Battle, Best of Friends",
            author: "William Guarnere, Edward Heffron, Robyn Post",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/5198Y9rehYL._SX329_BO1,204,203,200_.jpg",
            productUrl: "https://www.amazon.com/gp/product/0425224368",
            myNotes: [
                { note: "Because of these two men, and thousands more like them, the world was able to end the terrible global conflicts of the 1930’s and 40’s collectively known as World War II. Getting a first hand account of their experiences before, during and after the war is essential in helping to understand what it must have been like to live through total war." },
                { note: "Words can’t possibly do justice to describe the series of events these two men survived for months on as they moved across Europe. Best story was some German General having to surrender to Private Babe Heffron." }
            ]
        },
        {
            id: 8,
            title: "Stalling for Time: My Life as an FBI Hostage Negotiator",
            author: "Gary Noesner",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/51xbsMvCw7L._SX322_BO1,204,203,200_.jpg",
            productUrl: "https://www.amazon.com/gp/product/0525511288",
            myNotes: [
                { note: "An incredibly insightful behind the scenes look at not just the incidents at Ruby Ridge and Waco but decades of experiences with tense life and death situations from the perspective of the chief FBI negotiator." },
                { note: "This was widely and unexpectedly educational for me in terms of how to deal with people in certain very specific scenarios. I wish this book had been available 20 years ago, it might have changed a few things for me and others." }
            ]
        },
        {
            id: 7,
            title: "The Seekers",
            author: "Daniel J. Boorstin",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/51jXBAnhzAL._SX325_BO1,204,203,200_.jpg",
            productUrl: "https://www.amazon.com/gp/product/0375704752",
            myNotes: [
                { note: "Reading this I felt like I was back in high school taking Philosophy and Religion, but this time at my own pace and with no tests at the end." },
                { note: "I've read Boorstin before in his epic The Discoverers and again I'm amazed at the amount of content he is able to squeeze into every sentence. And with no loss of context or relationship with the other materials provided." }
            ]
        },
        {
            id: 6,
            title: "I Must Say: My Life As a Humble Comedy Legend",
            author: "Martin Short",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/51R2yrdJFRL._SX331_BO1,204,203,200_.jpg",
            productUrl: "https://www.amazon.com/gp/product/0062309528",
            myNotes: [
                { note: "Martin Short is an American icon who was born in Canada. Jewish since birth the man is a comedy legend. Oh wait, it say's that in the title. How redundent of him." },
                { note: "The man knows a lot of people in and out of Hollywood. Just ask him, he'll tell ya. Innerspace, Father of the Bride were among the movies I watched as a child. I know of ¡Three Amigos!, but haven't seen it yet." },
                { note: "I very much enjoyed reading this book. We all know of Martin Short, have seen him in Movies and/or TV over the decades. But this book tells of stories you probably don't know. And how far back those entertainment connections go." },
                { note: "Everybody in Lalawood knows that you haven't made it until you've been interviewed by the world renowned Jiminy Glick." }
            ]
        },
        {
            id: 5,
            title: "Shadows of Forgotten Ancestors",
            author: "Carl Sagan, Ann Druyan",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/51SrCHm5OlL.jpg",
            productUrl: "https://www.amazon.com/gp/product/0345384725",
            myNotes: [
                { note: "A brilliant and insightfully orchestrated collection of knowledge about our relationship with the other animals we share the Earth with." },
                { note: "Along with his wife Ann, Sagan captivates the reading, telling a complete story." }
            ]
        },
        {
            id: 4,
            title: "Welcome to the Universe",
            author: "Neil deGrasse Tyson, Michael A. Strauss, J. Richard Gott",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/81%2Bb0bnYzPL.jpg",
            productUrl: "https://www.amazon.com/gp/product/0691157243",
            myNotes: [
                { note: "This was an intense and thought provoking read, mainly geared as a college textbook for an introduction to astrophysics." },
                { note: "It brings the reader up to date with current thoughts and ideas on the origin of our universe and even what lies beyond. It explores the concepts of time travel in both directions and gives reasoned mathmatical backup to support what is presented, including well-documented visualizations." }
            ]
        },
        {
            id: 3,
            title: "Artemis",
            author: "Andy Weir",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/41rdzW8wCHL.jpg",
            productUrl: "https://www.amazon.com/gp/product/0553448129",
            myNotes: [
                { note: "A quick fun read about a young women trying to make a big financial score on the Moon." },
                { note: "I haven't read The Martian and wasn't sure what to expect from this author. The book wants to be a comedy/action thriller but really doesn't accomplish either. Not really my kind of read, seemed more of a young adult book to me." }
            ]
        },
        {
            id: 2,
            title: "Disrupted: My Misadventure in the Start-Up Bubble",
            author: "Dan Lyons",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/91xKRw-IpCL.jpg",
            productUrl: "https://www.amazon.com/gp/product/0316306096",
            myNotes: [
                { note: "Oh my! This is a true story? Misadventure is an understatement. The ending plays something like an episode of The Sopranos." },
                { note: "Anyone that has worked in either the tech or start-up industries over the last 50 years will understand this story. We've all lived one or both sides of this epic once, perhaps multiple times in our lives. A summarized version should be made available to those entering any venture funded frat house." }
            ]
        },
        {
            id: 1,
            title: "Sapiens: A Brief History of Humankind",
            author: "Yuval Noah Harari",
            coverImageUrl: "https://images-na.ssl-images-amazon.com/images/I/51zJS6PmxbL.jpg",
            productUrl: "https://www.amazon.com/gp/product/0062316095",
            myNotes: [
                { note: "I really enjoyed reading this book. The narrative, while slow at times, was incredibly interesting to me." },
                { note: "It provided an insightful comparison of humanity over time." }
            ]
        }
    ]

    export default {
        created() {
            window.document.title = 'Chris Davais - Books'
        },
        computed: {
            bookListStyles() {
                return {
                    "max-height": `${window.innerHeight - 700}px`,
                    "overflow-y": 'auto'
                };
            }
        },
        data: () => ({
            books: books
        })
    }
</script>

<style>
    .pBook {
        border-top: 1px solid goldenrod;
        clear: both;
        padding-top: 10px;
        margin-bottom: 10px;
    }

    #divLastUpdated {
        clear: both;
        white-space: nowrap;
    }
</style>
